import './menu.scss';
import './menu-wcag.scss';

const isDesktop = () => window.matchMedia("(min-width: 992px)").matches;

let initMobile = false;
let initDesktop = false;

const Menu = (container, isAnimation) => {

    const root = container,
          links = root.querySelectorAll('[aria-haspopup="true"]'),
          ENTER_KEY_CODE = 13,
          TAB_KEY_CODE = 9,
          ESC_KEY_CODE = 27;

    /**
     * Скрыть пункты меню, которые не содержат внутри себя target
     */
    const closeNotTargetedItems = target => {
        [].forEach.call(links, function(link) {
            if (!link.parentElement.contains(target)) {
                closeItem(link);
            };
        });
    };

    /**
     * Получить подменю для пункта по ссылке
     */
    const getMenuForItem = link => link.nextElementSibling;

    /**
     * Переключить расрытость пункта меню
     */
    const toggleItem = itemLink => {
        (itemLink.getAttribute('aria-expanded') === 'false') ? openItem(itemLink) : closeItem(itemLink);
    };

    /**
     * Открыть пункт меню
     */
    const openItem = itemLink => {
        const menu = getMenuForItem(itemLink);

        itemLink.setAttribute('aria-expanded', 'true');
        menu.style.display = '';

        /*убираем атрибут скрытости, чтобы подменю отобразилось с анимацией */

        if (isAnimation) {
            setTimeout(function(){
                menu.removeAttribute('aria-hidden');
            }, 0);

            document.body.classList.add('menu-open');
        };

        if (!isDesktop()) {
            document.body.classList.add('scrolling-disabled');
        };
    };

    /**
     * Закрыть подменю для пункта
     */
    const closeItem = itemLink => {

        const menu = getMenuForItem(itemLink);

        itemLink.setAttribute('aria-expanded', 'false');

        if (isAnimation) {
            menu.setAttribute('aria-hidden', 'true');
            document.body.classList.remove('menu-open');
        } else {
            menu.style.display = 'none';
        };

        if (!isDesktop()) {
            document.body.classList.remove('scrolling-disabled');
        };
    };

    [].forEach.call(links, function(link) {

        link.addEventListener('click', function(event){
            toggleItem(link);
        });

        link.addEventListener('keydown', function(event) {
            if (event.keyCode !== ENTER_KEY_CODE) return;
            toggleItem(link);
        });

        var menu = getMenuForItem(link);

        /**
         * По окончании анимации появления/скрытия подменю, если оно должно быть скрыто, делаем его display: none, чтобы скрыть окончательно
         */

        if (isAnimation) {
            menu.addEventListener('transitionend', function(event) {
                if (event.propertyName !== 'opacity') return;

                if (link.getAttribute('aria-expanded') === 'false') {
                    menu.style.display = 'none';
                }
            })
        };

    });

    if (isDesktop()) {
        document.addEventListener('click', function(event) {
            closeNotTargetedItems(event.target);
        });

        document.addEventListener('keyup', function(event) {
            if (event.keyCode !== TAB_KEY_CODE) return;
            closeNotTargetedItems(event.target);
        });

        /**
         * Закрываем подменю по нажатию клавиши ESC
         */
        document.addEventListener('keydown', function(event) {
            if (event.keyCode !== ESC_KEY_CODE) return;
            closeNotTargetedItems(null);
        });
    };

};

const createMenu = () => {

    if (isDesktop() && !initDesktop) {
        initDesktop = true;
        new Menu(document.querySelector('[data-role="menu"]'), true);
    };

    if (!isDesktop() && !initMobile) {
        initMobile = true;
        new Menu(document.querySelector('[data-role="mobile-menu"]'), false);
    };

};

new createMenu();

let resizeId;

window.addEventListener('resize', function() {
    clearTimeout(resizeId);
    resizeId = setTimeout(createMenu, 500);
});