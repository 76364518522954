import $ from 'jquery';
import 'lightslider';
import './lightslider.min.scss';
import './banners.scss';

import './banners-wcag.scss';

$(document).ready(function() {
    $("#banners").lightSlider({
        item: 5,
        autoWidth: false,
        slideMove: 1,
        loop: true,
        slideMargin: 0,

        prevHtml: '<span class="banners__arrow inline-icon-arrow-right inline-icon-arrow-right_left"></span>',
        nextHtml: '<span class="banners__arrow inline-icon-arrow-right"></span>',

        rtl: false,
        adaptiveHeight: true,

        thumbItem:0,
        pager: false,
        gallery: false,
        galleryMargin: 0,
        thumbMargin: 0,
        currentPagerPosition: 'middle',

        enableTouch: true,
        enableDrag: true,
        freeMove: true,
        swipeThreshold: 40,

        responsive : [
            {
                breakpoint: 1100,
                settings: {
                    item: 4
                }
            },
            {
                breakpoint: 830,
                settings: {
                    item: 3
                }
            },
            {
                breakpoint: 576,
                settings: {
                    item: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    item: 1
                }
            }
        ],

        onSliderLoad: function (el) {
            el.css("display","block");
        }
    });
});
